<template>
    <div class="about">

        <AppHeading title="About Us" />

        <AppAboutTop />

    </div>
</template>

<script>
export default {
    components: {
        AppHeading: () => import("@/components/Heading.vue"),
        AppAboutTop: () => import("@/components/AboutTop.vue"),
    }
}
</script>